<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">All rights reserved.</span>
    </div>
    <div class="mfs-auto">
      <span class="ml-1">Copyright &copy; {{new Date().getFullYear()}} apexprobox.com</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
